<template>
  <div>
    <el-row type="flex" class="row-button" justify="space-between"
      ><el-col :span="20">
        <div style="display: flex; justify-content: flex-start; align-items: center">
          <el-button v-read-excel="upload" type="primary">上传Excel</el-button>
          <p style="color: red; height: 32px; line-height: 32px; margin-left: 10px">
            请先下载模板后填写相关信息点击上传Excel,确认无误后点击确认申请
          </p>
        </div>
      </el-col>
      <el-button @click="tofinish" type="primary">确认申请</el-button>
    </el-row>
    <el-row
      style="
        border-top: 1px solid #ebeef5;
        margin-top:5px
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
        height: 40px;
        line-height: 40px;
      "
    >
      <el-col :span="24" style="font-size: 16px; text-align: center; color: #666666">
        <span style="font-size: 20px; font-weight: 500; margin-left: 10px">权利人信息导入表</span>
      </el-col>
    </el-row>
    <el-row class="row-table">
      <el-table
        :data="dataList"
        v-loading="tableLoading"
        border
        stripe
        fit
        max-height="400px"
        :header-cell-style="{ 'text-align': 'center' }"
        class="public-sp-el-table table-self userTable"
      >
        <el-table-column label="名称">
          <template slot-scope="scope">
            <span v-if="scope.row['名称']">
              {{ scope.row['名称'] }}
            </span>
            <span v-if="scope.row['买方名称']">
              {{ scope.row['买方名称'] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="申请人类型" prop="申请人类型"></el-table-column>
        <el-table-column label="证件种类" prop="证件种类"></el-table-column>
        <el-table-column label="证件号码" prop="证件号码"></el-table-column>
        <el-table-column label="性别" prop="性别"></el-table-column>
        <el-table-column label="是否持证人" prop="是否持证人"></el-table-column>
        <el-table-column label="联系电话" prop="联系电话"></el-table-column>
        <el-table-column label="共有方式" prop="共有方式"></el-table-column>
        <el-table-column label="婚姻状况" prop="婚姻状况"></el-table-column>
        <el-table-column label="不动产单元号" prop="不动产单元号"></el-table-column>
        <el-table-column label="状态" prop="状态"></el-table-column>
      </el-table>
    </el-row>
    <el-row
      style="
        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
        margin-top: 20px;
        height: 40px;
        line-height: 40px;
      "
    >
      <el-col :span="24" style="font-size: 16px; text-align: center; color: #666666">
        <span style="font-size: 20px; font-weight: 500; margin-left: 10px">批量办理结果表</span>
      </el-col>
    </el-row>

    <el-row
      ><el-table
        :data="resultData"
        border
        stripe
        fit
        max-height="400px"
        :header-cell-style="{ 'text-align': 'center' }"
        class="public-sp-el-table table-self userTable"
      >
        <el-table-column label="业务号" prop="wwywh"></el-table-column>
        <el-table-column label="权利人名称">
          <template slot-scope="scope">
            <span v-if="scope.row.qlr['名称']">
              {{ scope.row.qlr['名称'] }}
            </span>
            <span v-if="scope.row.qlr['买方名称']">
              {{ scope.row.qlr['买方名称'] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="保存状态" prop="checkStatus"></el-table-column>
        <el-table-column prop="address" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-link
              size="small"
              type="primary"
              icon="el-icon-circle-check"
              style="margin-left: 5px"
              @click="editRow(scope.row)"
              >查看</el-link
            >
          </template>
        </el-table-column>
      </el-table></el-row
    >
  </div>
</template>

<script>
import { localDataUser } from '@iebdc/utils/local-data';
import { mapMutations, mapGetters } from 'vuex';
import { getCookie } from '@iebdc/utils/cookies';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
import applicantRightService from '@/pages/iebdc/api/apply/applicant-right';
import sqrService from '@/pages/iebdc/api/apply/applicant-sqr';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import { Loading } from 'element-ui';
export default {
  name: 'batchProcessingApply',
  computed: {
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        console.log(to, from);
      },
    },
    deep: true,
    immediate: true, // immediate选项可以开启首次赋值监听
  },
  data() {
    return {
      tableLoading: false,
      dataList: [],
      resultData: [],
      firstFlow: {},
      loadingTest: '',
      successHX: 0, //核验成功数
      successYWR: 0, //义务人添加成功数
      successQLR: 0, //权利人添加成功数
      user: localDataUser.get(),
    };
  },
  mounted() {
    this.firstFlow = this.$route.params;
    if (JSON.stringify(this.firstFlow) == '{}') {
      this.$router.push({
        name: 'batchProcessingIndex',
      });
    }
  },
  methods: {
    ...mapMutations('wysq-data', ['updateFirstFlow']),
    startLoading(text) {
      this.loading = Loading.service({
        lock: true,
        text, //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)', //遮罩层背景色
        target: '.userTable', // 需要遮罩的区域
        customClass: 'mask',
      });
    },
    editRow(row) {
      console.log(this.firstFlow);
      this.updateFirstFlow({
        name: this.firstFlow.subFlowName,
        netFlowdefCode: this.firstFlow.netFlowdefCode,
      });
      localStorage.setItem('ywh', row.wwywh);
      this.$router.push({
        name: 'applicantTable',
        params: { ywh: row.wwywh },
      });
    },
    endLoading() {
      this.loading.close();
    },
    upload(list) {
      this.dataList = list;
    },
    tofinish() {
      this.startLoading('正在进行产权证核验');
      this.successHX = 0;
      this.successYWR = 0;
      this.successQLR = 0;
      this.resultData = [];
      console.log(this.dataList);
      this.dataList.forEach((ele) => {
        this.getBdcxx(ele);
      });
    },
    getBdcxx(row) {
      let params = [
        {
          bdclx: 'HOUSE',
          bdcdyh: [row['不动产单元号']],
          qydm: getCookie('selectedAreaCode'),
          lcdm: this.firstFlow.netFlowdefCode,
        },
      ];
      applicantRightService
        .getBdcxxByDyh({ params: params })
        .then((res) => {
          if (res.success) {
            this.successHX += 1;
            this.loading.setText(`正在进行产权证核验${this.successHX}/${this.dataList.length}`);
            let bdcxx = {};
            bdcxx.qlxxExMhs = this.getqlxxExMhs(res.data.bdcxx);
            bdcxx.qlxxEx = {
              sqdjyy: this.firstFlow.fourTypeData.name,
              sffbcz: false,
              sfdbz: false,
              sqzsbs: '0',
            };
            this.toRegister(bdcxx).then((res) => {
              if (res.success) {
                this.resultData.push({
                  wwywh: res.data.wwywh,
                  qlxxId: res.data.qlxxId,
                  zywh: res.data.ywh[0],
                  ...bdcxx,
                  qlr: row,
                  checkStatus: '',
                });
              }
              if (this.successHX === this.dataList.length) {
                setTimeout(() => {
                  this.loading.setText('正在保存义务人信息');
                  this.resultData.forEach((ele) => {
                    this.saveYwr(ele);
                  });
                }, 100);
              }
            });
          }
        })
        .catch((err) => {
          console.log('进入产权核验失败流程');
          let bdcxx = {};
          bdcxx.qlxxExMhs = [
            {
              zlProvince: getCookie('selectedShengTitle') ? getCookie('selectedShengTitle') : '新疆维吾尔自治区',
              zlCity: getCookie('selectedShiTitle'),
              zlArea: getCookie('selectedAreaTitle'),
            },
          ];
          this.toRegister(bdcxx).then((res) => {
            if (res.success) {
              this.resultData.push({
                wwywh: res.data.wwywh,
                qlxxId: res.data.qlxxId,
                zywh: res.data.ywh[0],
                checkStatus: '产权核验失败 ',
                qlr: row,
              });
            }
            this.successHX += 1;
            if (this.successHX === this.dataList.length) {
              this.endLoading();
              setTimeout(() => {
                this.loading.setText('正在保存义务人信息');
                this.resultData.forEach((ele) => {
                  this.saveYwr(ele);
                });
              }, 500);
            }
          });
        });
    },

    //判断当前义务人权利人是否重复
    async judgeDuplicate(params) {
      const { data, success, message } = await applicantOperatorService.judgeDuplicate(params);
      if (success && data) {
        return data;
      } else {
        this.$message.error(message);
        return data;
      }
    },
    saveYwr(row, index) {
      console.log(row);
      let userInfo = sqrService.getUserInfo();
      console.log(userInfo);
      if (this.user.hasOwnProperty('jbrData')) {
        userInfo.whetherDlr = '1';
        userInfo.dlrlx = '0';
        userInfo.dlrdh = this.user.jbrData.lxdh;
        userInfo.dlrzjh = this.user.jbrData.sfzh;
        userInfo.dlrmc = this.user.jbrData.name;
        userInfo.dlrzjzl = this.user.jbrData.sfzhzl;
      }
      userInfo.gyfs = '0';
      userInfo.zjzl = userInfo.zjzl ? userInfo.zjzl : '1';
      userInfo.ywh = row.zywh;
      let params = {
        ywh: row.zywh,
        qlxxId: row.qlxxId,
        ywr: userInfo,
      };
      this.judgeDuplicate(params).then((res) => {
        if (res) {
          userInfo.category = '8'; //预告义务人
          this.addObligor(userInfo)
            .then((res1) => {
              this.loading.setText(`正在保存义务人信息${this.successYWR}/${this.resultData.length}`);
              if (!res1) this.resultData[index] += '保存义务人失败';
              else {
                let paramsTzr = {
                  ywh: row.zywh,
                  tzrmc: userInfo.ywrmc,
                  tzdh: userInfo.dh,
                  category: '8',
                };
                this.saveTzr(paramsTzr);
              }
              if (this.successYWR === this.resultData.length) {
                this.loading.setText('正在保存权利人信息');
                this.resultData.forEach((ele, index) => {
                  this.saveQlr(ele, index);
                });
              }
            })
            .catch((err) => {
              this.successYWR += 1;
            });
        } else {
          this.resultData[index] += '义务人重复';
          this.successYWR += 1;
          this.loading.setText(`正在保存义务人信息${this.successYWR}/${this.resultData.length}`);
          if (this.successYWR === this.resultData.length) {
            this.loading.setText('正在保存权利人信息');
            this.resultData.forEach((ele) => {
              this.saveQlr(ele, index);
            });
          }
        }
      });
      // if (this.judgeDuplicate(params)) {
      //   console.log(userInfo);
      // }
    },
    getHYZK(data) {
      switch (data) {
        case '未婚':
          return 1;
        case '已婚':
          return 2;
        case '涉外婚姻':
          return 3;
        default:
          break;
      }
    },
    saveTzr(params) {
      applicantOperatorService.saveTzr(params).then((res) => {});
    },
    //保存权利人信息
    saveQlr(row, index) {
      let qlr = row.qlr;
      if (qlr['共有方式'] !== '单独所有' && !qlr['共有人姓名']) {
        this.$message.error('共同所有需填写共有人信息');
      } else {
        let params = {
          ywh: row.zywh,
          qlxxId: row.qlxxId,
          qlr: {
            category: '7',
            dh: qlr['联系电话'],
            gyfs: qlr['共有方式'] === '单独所有' ? '0' : '1',
            qlrlx: '1',
            qlrmc: qlr['名称'] || qlr['买方名称'],
            sfczr: qlr['是否持证人'] === '是' ? '1' : '0',
            sqrType: 'qlr',
            whetherDlr: '2',
            xb: qlr['性别'] === '男' ? '1' : '0',
            ywh: row.zywh,
            ywrmc: qlr['名称'] || qlr['买方名称'],
            zjh: qlr['证件号码'],
            zjzl: '1',
            marriageStatus: this.getHYZK(qlr['婚姻状况']),
          },
        };
        this.judgeDuplicate(params).then((res) => {
          if (res) {
            this.addRightHolder(params.qlr).then((res1) => {
              this.successQLR += 1;
              this.loading.setText(`正在保存权利人信息${this.successQLR}/${this.resultData.length}`);
              //是否含有共有人
              if (qlr['共有方式'] !== '单独所有' && qlr['共有人姓名']) {
                this.saveGYR(row, index);
              }
              //保存联系人
              let paramsTzr = {
                ywh: row.zywh,
                tzrmc: qlr['名称'] || qlr['买方名称'],
                tzdh: qlr['联系电话'],
                category: '7',
              };
              this.saveTzr(paramsTzr);
              if (this.successQLR === this.resultData.length) {
                this.endLoading();
                console.log(this.resultData);
              }
            });
          } else {
            this.successQLR += 1;
            this.loading.setText(`正在保存权利人信息${this.successQLR}/${this.resultData.length}`);
            this.resultData[index].checkStatus += '权利人重复 ';
            console.log(this.resultData);
          }
        });
      }
    },
    async addRightHolder(userInfo) {
      const { data, success, message } = await applicantOperatorService.addRightHolder(userInfo);
      return success;
    },

    async addObligor(userInfo) {
      const { data, success, message } = await applicantOperatorService.addObligor(userInfo);
      if (success) {
        this.successYWR += 1;
        return success;
      } else {
        this.successYWR += 1;
        return success;
      }
    },
    //保存共有人信息
    saveGYR(row, index) {
      let qlr = row.qlr;
      let params = {
        ywh: row.zywh,
        qlxxId: row.qlxxId,
        qlr: {
          category: '7',
          dh: qlr['共有人联系电话'],
          gyfs: qlr['共有人共有方式'] === '单独所有' ? '0' : '1',
          qlrlx: '1',
          qlrmc: qlr['共有人姓名'],
          sfczr: qlr['共有人是否持证人'] === '是' ? '1' : '0',
          sqrType: 'qlr',
          whetherDlr: '2',
          xb: qlr['共有人性别'] === '男' ? '1' : '0',
          ywh: row.zywh,
          zjh: qlr['共有人证件号码'],
          zjzl: '1',
          marriageStatus: this.getHYZK(qlr['共有人婚姻状况']),
        },
      };
      this.judgeDuplicate(params).then((res) => {
        if (res) {
          this.addRightHolder(params.qlr).then((res1) => {
            if (!res1) this.resultData[index].checkStatus += '共有人保存失败 ';
            else {
              let paramsTzr = {
                ywh: row.zywh,
                tzrmc: qlr['共有人姓名'],
                tzdh: qlr['共有人联系电话'],
                category: '7',
              };
              this.saveTzr(paramsTzr);
            }
          });
        } else {
          this.resultData[index].checkStatus += '权利人重复 ';
        }
      });
    },
    getqlxxExMhs(huArray) {
      (huArray || []).forEach((item, index) => {
        item.fwcqmj = item.jzmj;
        item.zlProvince = getCookie('selectedShengTitle') ? getCookie('selectedShengTitle') : '新疆维吾尔自治区';
        item.zlCity = getCookie('selectedShiTitle');
        item.zlArea = getCookie('selectedAreaTitle');
      });
      return huArray;
    },
    // 在线登记, 处理需要保存给后台的数据
    async toRegister(bdcxx) {
      /**
       * params 需要提交给后台的数据
       * localDataUser 用户信息
       * firstFlow 选择的申请类型数据
       * firstFlow.secondTypeData 选择的申请类型二级类
       * firstFlow.thirdTypeData 选择的申请类型三级类
       * firstFlow.fourTypeData 选择的申请类型四级类
       * */
      console.log(bdcxx);
      console.log(this.firstFlow);
      const params = {
        sfyddbl: 0,
        qlxz: '',
        userName: localDataUser.get().realName,
        lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
        userId: localDataUser.get().id, // 用户id
        sqrlx: localDataUser.get().userCategory, // 申请人类型
        bdclb: this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
        flowcode: this.firstFlow.thirdTypeData.flowCode,
        netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
        flowname: this.firstFlow.thirdTypeData.name,
        djdl: this.firstFlow.thirdTypeData.djdl,
        qllx: this.firstFlow.thirdTypeData.qllx,
        subFlowcode: this.firstFlow.fourTypeData.code,
        subFlowname: this.firstFlow.fourTypeData.name,
        taskCode: this.firstFlow.thirdTypeData.taskCode,
        pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
        taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
        qlxxEx: bdcxx ? bdcxx.qlxxEx : [],
        qlxxExMhs: bdcxx ? bdcxx.qlxxExMhs : [],
        ...this.getSelectedOrgInfo,
      };
      const { data, success, message } = await applicantSaveService.saveSqxx(params);
      if (success) {
        return { data, success };
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row-table {
}

/deep/ .el-table {
  border-top: 1px solid rgba(241, 241, 241, 1);
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  //   height: inherit !important;
  //   .cell.el-tooltip
  //     white-space: normal;

  th {
    height: 53px !important;
    font-size: 14px !important;
    font-family: Microsoft YaHei !important;
    font-weight: 400 !important;
    color: rgba(51, 51, 51, 1) !important;
  }

  .el-table__row--striped td {
    background-color: rgba(247, 250, 255, 1) !important;
  }

  .el-table__fixed-right-patch {
    height: 53px !important;
  }

  .el-table__fixed-right {
    .el-table__fixed-body-wrapper {
      top: 53px !important;
    }
  }
}
</style>
